import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'yuno-admin-routing',
	standalone: true,
	imports: [RouterModule],
	template: ` <router-outlet />`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutingComponent {}
